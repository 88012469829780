import { FC } from 'react';
import { Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import Flag from 'react-flagpack';

interface CountryNameProps {
  readonly countryCode: string;
  readonly size?: 's' | 'm' | 'l';
}

const CountryName: FC<CountryNameProps> = ({ countryCode, size }) => {
  let countryName: string;
  try {
    countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode) || countryCode;
  } catch {
    countryName = countryCode;
  }

  const flagCode = countryCode === 'GB' ? 'GB-UKM' : countryCode;

  return (
    <ErrorBoundary fallbackRender={() => <>unknown</>}>
      <Typography noWrap component="span">
        <Flag code={flagCode} size={size ?? 'm'} />
        {'  '}
        {countryName}
      </Typography>
    </ErrorBoundary>
  );
};

export default CountryName;
